import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "errPage-container" }
const _hoisted_2 = { class: "list-unstyled" }
const _hoisted_3 = { class: "link-type" }
const _hoisted_4 = ["src"]
const _hoisted_5 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_button = _resolveComponent("el-button")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_el_col = _resolveComponent("el-col")!
  const _component_el_row = _resolveComponent("el-row")!
  const _component_el_dialog = _resolveComponent("el-dialog")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_el_button, {
      icon: "el-icon-arrow-left",
      class: "back-btn",
      onClick: _ctx.back
    }, {
      default: _withCtx(() => _cache[2] || (_cache[2] = [
        _createTextVNode(" 返回 ")
      ])),
      _: 1
    }, 8, ["onClick"]),
    _createVNode(_component_el_row, null, {
      default: _withCtx(() => [
        _createVNode(_component_el_col, { span: 12 }, {
          default: _withCtx(() => [
            _cache[6] || (_cache[6] = _createElementVNode("h1", { class: "text-jumbo text-ginormous" }, " Oops! ", -1)),
            _cache[7] || (_cache[7] = _createTextVNode(" gif来源")),
            _cache[8] || (_cache[8] = _createElementVNode("a", {
              href: "https://zh.airbnb.com/",
              target: "_blank"
            }, "airbnb", -1)),
            _cache[9] || (_cache[9] = _createTextVNode(" 页面 ")),
            _cache[10] || (_cache[10] = _createElementVNode("h2", null, "你没有权限去该页面", -1)),
            _cache[11] || (_cache[11] = _createElementVNode("h6", null, "如有不满请联系你领导", -1)),
            _createElementVNode("ul", _hoisted_2, [
              _cache[4] || (_cache[4] = _createElementVNode("li", null, "或者你可以去:", -1)),
              _createElementVNode("li", _hoisted_3, [
                _createVNode(_component_router_link, { to: "/dashboard" }, {
                  default: _withCtx(() => _cache[3] || (_cache[3] = [
                    _createTextVNode(" 回首页 ")
                  ])),
                  _: 1
                })
              ]),
              _cache[5] || (_cache[5] = _createElementVNode("li", { class: "link-type" }, [
                _createElementVNode("a", { href: "" }, "随便看看")
              ], -1)),
              _createElementVNode("li", null, [
                _createElementVNode("a", {
                  href: "#",
                  onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.dialogVisible=true), ["prevent"]))
                }, "点我看图")
              ])
            ])
          ]),
          _: 1
        }),
        _createVNode(_component_el_col, { span: 12 }, {
          default: _withCtx(() => [
            _createElementVNode("img", {
              src: _ctx.errGif,
              class: "some-gif",
              width: "313",
              height: "428",
              alt: "Girl has dropped her ice cream."
            }, null, 8, _hoisted_4)
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_el_dialog, {
      visible: _ctx.dialogVisible,
      "onUpdate:visible": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.dialogVisible) = $event)),
      title: "随便看"
    }, {
      default: _withCtx(() => [
        _createElementVNode("img", {
          src: _ctx.ewizardClap,
          class: "some-img"
        }, null, 8, _hoisted_5)
      ]),
      _: 1
    }, 8, ["visible"])
  ]))
}